import './App.css';
import { useRef } from 'react';
import Chatroom from './Chatroom';
import { db } from './firebase';
import { ref, update, remove } from 'firebase/database';


function App() {

  var nameRef = useRef()

  const usersRef = ref(db, "onlineUser")


  var currentSavedName = localStorage.getItem("chatuser") || "none"

  const handleSubmit = () => {
    var updatedData = {}
    updatedData[nameRef.current.value] = ""
    localStorage.setItem("chatuser", nameRef.current.value.toLowerCase()); 
    update(usersRef, updatedData)
    console.log(nameRef.current.value);
    currentSavedName = localStorage.getItem("chatuser")
    window.location.reload()
  }

  return (
    currentSavedName === "none" ? 
    <div>
      <h2>Gib deinen Namen ein!</h2>
      <input type='text' ref={nameRef} id='nameTb' placeholder='Name'/>
      <input type="button" id='submit' value="Chat beitreten" onClick={ handleSubmit }/>
    </div>
    : 
    <Chatroom/>
  );
}

export default App;
