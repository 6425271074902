import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCAbWdTCgNZMYt9WpKbzs5ywCtrwE0kZBc",
  authDomain: "schulchat-14908.firebaseapp.com",
  projectId: "schulchat-14908",
  storageBucket: "schulchat-14908.appspot.com",
  messagingSenderId: "1087419866236",
  appId: "1:1087419866236:web:72579f7ff3d8066ef3fdf9",
  databaseURL: "https://schulchat-14908-default-rtdb.europe-west1.firebasedatabase.app/"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app)