import React, { useEffect, useRef, useState } from 'react'
import { db } from './firebase';
import { onValue, child, ref, push, remove } from 'firebase/database';

const dbRef = ref(db, "chat")


function Chatroom() {


	const [messages, setMessages] = useState([])
	const [onlineUser, setOnlineUser] = useState("")

	useEffect(() => {
		onValue(dbRef, snap => {
			var messageArray = []
			console.log(snap.val())
			if(snap.val()){
			for(let message in snap.val()) {
				messageArray[messageArray.length] = {...snap.val()[message], id: message}
			}}
			setMessages(messageArray)
			scrollToLast();
		})

		onValue(ref(db, "onlineUser"), snap => {
			var oUsers = []
			var oUserString = ""
			for(let user in snap.val()) {
				oUsers[oUsers.length] = user;
			}
			oUsers.map((user, index) => index === oUsers.length - 1 ? oUserString += user.toUpperCase() : oUserString += user.toUpperCase() + " | ")
			setOnlineUser(oUserString)
		})
	}, [])

	console.log(messages)


  const logOut = () => {
	const userRef = ref(db, "onlineUser")
	remove(child(userRef, localStorage.getItem("chatuser")))
    localStorage.removeItem("chatuser");
    window.location.reload()
  }

  function scrollToLast() {
	var messagesCon = document.getElementById("messagesContainer")
	setTimeout(e => messagesCon.scrollTop = messagesCon.scrollHeight, 100)

  }


  window.onbeforeunload = logOut;

  const sendMessage = () => {
	if(messageTbRef.current.value !== "") {
	const date = new Date();
	const currentTime = date.getHours() + ":" + date.getMinutes();
	
	var messageData = {
		message: messageTbRef.current.value,
		time: currentTime,
		name: localStorage.getItem("chatuser")
	}

	push(dbRef, messageData).then(e => messageTbRef.current.value = "") }
  }


  window.onkeydown = e => {
	if(e.key === "Enter" && messageTbRef.current.value !== "") {
		sendMessage()
	}
  }


  const messageTbRef = useRef()

  return (
    <div>
		<div id="header">
			<p>jetzt online: <strong>{onlineUser}</strong></p>
		</div>
      	<div id='chatRoom'>
			<div id="messagesContainer">

				{
					messages.map(message => {
						if(message.name.toLowerCase() === localStorage.getItem("chatuser").toLowerCase()) {
							return(<MyMessage key={message.id} name={message.name} messageId={message.id} time={message.time} messageContent={message.message}/>)
						} else {
							return(<MessageGot key={message.id} name={message.name} time={message.time} messageContent={message.message}/>)
						}
					})
				}

				
				
				
			</div>

			<div id="sendBar">
				<input type="text" id="messageTb" ref={messageTbRef} placeholder='Deine Nachricht'/>
				<input type="button" id='sendMessage' value="➥" onClick={sendMessage} />
			</div>
		</div>
		<div id='footer'>
			<input type="button" id='clear' value="Chat leeren" onClick={clearChat}/>
			<p>Angemeldet als <strong>{localStorage.getItem("chatuser")}</strong></p>
			<input type="button" id='logOut' value="Abmelden" onClick={logOut}/>
		</div>
    </div>
  )
}

function deleteMessage(id) {
	remove(child(dbRef, id))
}

function clearChat() {
	remove(dbRef)
}


function MessageGot({name, time, messageContent}) {
	return(
	<div className="message">
		<p className="senderName">{name}</p>
		<p className="timeSent">{time}</p>
		<p className="messageContent">{messageContent}</p>
	</div>
	)
}

function MyMessage({name, time, messageId, messageContent}) {
	return(
		<div className='mine'>
		<div className="message myMessage">
			<p className="senderName">{name}</p>
			<p className="timeSent">{time}</p>
			<p className="messageContent">{messageContent}</p>
			<button className='deleteBtn' onClick={()=>deleteMessage(messageId)}>✖</button>
		</div>
		</div>
	)
}

export default Chatroom